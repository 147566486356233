import { Fragment, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import toast from "react-hot-toast";
import PasswordInput from "./PasswordInput";
import AuthLayout from "../layouts/AuthLayout";
// import {ICountry } from "../../modules/components";
import PrimaryButton from "../buttons/PrimaryButton";
import { AccountType, InputValidator } from "../../modules/enums";
import { Loading } from "../../utils/services/constants";
import APIService from "../../utils/services/api.service";
import BackSVG from "../../assets/images/svgs/arrow-left.svg";
import SuccessSVG from "../../assets/images/svgs/Successful.svg";
import closeSVG from "../../assets/images/svgs/close-circle.svg";
import { emailValidator } from "../../utils/services/validator.service";
import { GetStartedTabs } from "../../utils/mapper/Mapper";



const Register = () => {
  const navigate = useNavigate(), location = useLocation()
  const [step, setStep] = useState(1);
  const [fullName, setFullName] = useState("");
  const [accountType, setAccountType] = useState<string>(location.state);
  const [businessName, setBusinessName] = useState("");
  const [loadState, setLoadState] = useState(false);
  // const [countries, setCountries] = useState<ICountry[]>([]);
  // const [country, setCountry] = useState<ICountry | undefined>(countries[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorState, setEmailErrorState] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // const fetchCountries = async () => {
  //   const { data: responseData } = await APIService.getSupportedCountries();
  //   if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
  //   else {
  //     setCountries(responseData.data)
  //   }
  // };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);


  const isInvalid = !fullName || !email || emailErrorState
    || emailValidator(email).validState === InputValidator.INVALID || !password || !confirmPassword

  const nameParts = fullName.trim().split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  const isBusinessInvalid = !businessName;

  const goBack = () => {
     navigate(-1);
  }


  const handleFieldValidation = (data: string) => {
    const validatedEmail = emailValidator(data);
    setEmail(validatedEmail.validEmail);
    const isEmailValid = validatedEmail.validState !== InputValidator.VALID;
    setEmailErrorState(isEmailValid);
  };

  const getHeader = (key: string) => {
    switch (key) {
      case "TRUQ":
        return "on truQ";
      case "SIJU":
        return "on Siju";
      case "PARTNER":
        return "as a Partner";
      case "MOVE":
        return "on Move";
      default:
        return null;
    }
  };

  const getLink = (key: string) => {
    switch (key) {
      case "TRUQ":
        return "https://dodge-super-app.vercel.app/auth/login";
      case "SIJU":
        return "https://app.siju.it/auth/login";
      case "PARTNER":
        return "https://app.mytruq.com/auth/login";
      default:
        return "https://app.mytruq.com/auth/login";
    }
  };

  const getPayload = (key: string) => {
    switch (key) {
      case "TRUQ":
        return {
          type: 'TRUQ',
          country: "NGA",
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
        }
      case "SIJU":
        return {
          type: 'SIJU',
          country: "NGA",
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
          business: businessName,
        }
      case "PARTNER":
        return {
          type: 'TRUQ',
          country: "NGA",
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
        }
      default:
        return {
          type: 'TRUQ',
          country: "NGA",
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
        }
    }
  };

  const handleOnChange = (data: string) => {
    setAccountType(data)
  }

  const handleOnboarding = async () => {
    setLoadState(true);
    let payload = getPayload((accountType))
    const { data: responseData } = await APIService.onboardLead(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      toast.success(responseData.message);
      setStep(2)
    }
    setLoadState(false);
  }

  const handleMail = async () => {
    window.open('mailto:', '_self');
  }


  return (
    <AuthLayout step={step}>
      <Fragment>
        {step === 1 && (
          <Fragment>
            <div className="d-flex flex-row my-2 cursor-pointer" onClick={goBack}>
              <Image src={BackSVG} alt="GO_BACK" />
              <p className="text-black">Go Back</p>
            </div>
            <h4 className="text-start mt-1 mt-lg-5 text-black">
              {accountType === null ? "Select an option to get started" : `Get Started ${getHeader(accountType)}`}
            </h4>
            <p className="text-office-brown-700 mb-3 text-start">
              {accountType === "TRUQ" ? "Sign up on truQ to get access to all you need to grow your logistics hustle." :  accountType === "SIJU" ? "Sign up on Siju to unlock continuously efficient 3PL operations. Access vehicles on truQ and streamline your logistics operations." : accountType === "PARTNER" ? "Sign up as a Partner to Make extra revenue by offering an all-in-one seamless logistics and home maintenance service to your customers." : accountType === "MOVE" ? "Sign up on Move to access seamless logistics and home maintenance service, before during and after your move." : "" }
            </p>
            <div>
              <div className="row mt-3">
                {
                  GetStartedTabs.map((item, index) => 
                  <div className="w-120 mb-2" key={index}>
                    <label className={`cursor-pointer card  border-primary ${accountType === item.value ? "bg-lilac" : ""}`} htmlFor={item.id}>
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <h6 className="">{item.text}</h6>
                        <input type="radio" id={item.id} name="account_type" value={item.value} className="custom-radio"
                          checked={accountType === item.value}
                          onChange={event => handleOnChange(event.target.value)} />
                      </div>
                    </label>
                  </div>)
                }
              </div>
            </div>
            { accountType !== null && <>
            <div className="form-floating my-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={fullName}
                placeholder="full_name"
                onChange={(e) => setFullName(e.target.value)}
              />
              <label>Full Name</label>
            </div>
            {(accountType === AccountType.SIJU || accountType === AccountType.PARTNER) && <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={businessName}
                placeholder="business_name"
                onChange={(e) => setBusinessName(e.target.value)}
              />
              <label>Business Name</label>
            </div>}
            {
              <div className="d-flex flex-column">
                {/* <div className={`form-floating mb-4 ${(location.state === "move" || location.state === "truq_app") ? "order-3" : "order-1"}`}>
                  <select
                    className="form-select form-lightblue"
                    value={country ? country.country_code : ''}
                    onChange={(e) => {
                      const selectedCountry = countries.find(item => item.country_code === e.target.value);
                      setCountry(selectedCountry || undefined);
                    }}
                  >
                    <option value="">Select a country</option>
                    {Array.isArray(countries) && countries.length > 0 ? (
                      countries.map((item) => (
                        <option key={item.country_code} value={item.country_code}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No countries available
                      </option>
                    )}
                  </select>
                  <label className="">Country</label>
                  <Image src={ArrowDownSVG} alt="drop_down" className="form-icon" width={20} height={20} />
                </div> */}
                <div className={`form-floating mb-4 ${(accountType === AccountType.MOVE || accountType === AccountType.TRUQ) ? "order-1" : "order-3"}`}>
                  <input
                    type="text" placeholder="email address" value={email}
                    className={`form-control form-lightblue ${emailErrorState && "is-invalid"}`}
                    onChange={(e) => handleFieldValidation(e.target.value)} />
                  <label>{accountType === AccountType.MOVE ? "" : "Work"} Email Address</label>
                </div>
                <div className={`form-floating mb-4 ${(accountType === AccountType.MOVE || accountType === AccountType.TRUQ) ? "order-2" : "order-4"}`}>
                  <input
                    type="text"
                    className="form-control form-lightblue"
                    value={phoneNumber}
                    placeholder="phone_number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <label>Phone Number</label>
                </div>
              </div>
            }
            <PasswordInput name="password" value={password} isValidatorRequired
              setInput={value => setPassword(value)} placeholder="Password" />
            <PasswordInput name="confirmPassword" value={confirmPassword} isValidatorRequired
              setInput={value => setConfirmPassword(value)} placeholder="Confirm Password" />

            <PrimaryButton
              btn={{
                text: 'Save & Continue',
                loadState: loadState,
                disabledState: isInvalid,
                btnClassName: 'btn-block my-4 fw-bold w-100',
                clickFn: () => handleOnboarding()
              }} />

            <div className="d-flex justify-content-center">
              <div className="mt-2 p-3">
                <p className="text-dark text-center">
                  Already have an account?
                  <NavLink to={getLink(accountType)} className="text-primary-emphasis ms-2 fw-bold" target="_blank">
                    Sign in
                  </NavLink>
                </p>
              </div>
            </div>
            </>}
          </Fragment>
        )}

        {step === 2 && (
          <Fragment>
            <div className="my-5 success-card">
              <p onClick={goBack} className="py-4 cursor-pointer"><Image src={closeSVG} alt="close" className="me-2" />Close</p>
              <Image src={SuccessSVG} alt="successful" width={200} height={180} className="mx-auto mb-3" />
              <h4 className="text-success-dark text-center py-3">Email has been Sent !</h4>
              <p className="text-medium text-center font-size-14">A whole new world of logistics possibilities awaits!</p>
              <PrimaryButton
                btn={{
                  text: 'Check your Email for next steps!',
                  disabledState: isBusinessInvalid,
                  btnClassName: 'btn-block my-5 mx-auto',
                  clickFn: handleMail
                }}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    </AuthLayout>
  );
};

export default Register;
